const PUSHER_CLASS = 'js-gallery-pusher';

$('.gallery--deck').on('showing.jq.offie', '[data-toggle="offie"]', function (event) {
  var
    $eventTarget = $(event.target),
    $buckleElement = $eventTarget.closest('[data-toggle="buckle"]'),
    buckle = $buckleElement.length ? $buckleElement.data('buckle'): null
  ;

  // Render the "pusher" which will push content beneath the dropdown.
  if (0 === $eventTarget.find('.' + PUSHER_CLASS).length) {
    $eventTarget.append('<div class="' + PUSHER_CLASS + '" />');
  }

  var
    $pusher = $eventTarget.find('.' + PUSHER_CLASS),
    $offieTarget = event.offie.getTarget()
  ;

  // Animate to scroll height.
  $pusher.css({'height': $offieTarget[0].scrollHeight});
});

$('.gallery--deck').on('hiding.jq.offie', '[data-toggle="offie"]', function (event) {
  var
    PUSHER_CLASS = 'js-gallery-pusher',
    $pusher = $(event.target).find('.' + PUSHER_CLASS)
  ;

  // Animate to 0 to simulate the dropdown animation.
  $pusher.css({'height': '0'});
});

$('.gallery--deck [data-toggle="buckle"]').on('ajaxComplete.jq.buckle', function (event) {
  var
    $eventTarget = $(event.target),
    $offieTarget = $eventTarget.data('relatedOffieDropdown'),
    $pusher = $eventTarget.find('.' + PUSHER_CLASS)
  ;

  $pusher.css({'height': $offieTarget[0].scrollHeight});
});

$('.js-offie--buckle-content').on('showing.jq.offie', function (event) {
  var
    $eventTarget = $(event.target),
    $buckleElement = $eventTarget.closest('[data-toggle="buckle"]'),
    buckle = $buckleElement.length ? $buckleElement.data('buckle'): null,
    $offieTarget = event.offie.getTarget()
  ;

  if (buckle) {
    // Special case for buckle to update the height at the end of ajax call.
    $buckleElement.data('relatedOffieDropdown', $offieTarget);

    // Trigger the buckle fetch.
    buckle.fetch({});
  }
});

$('.js-buckle--offie-content').on('ajaxComplete.jq.buckle', function (event) {
  var
    $eventTarget = $(event.target),
    offieInstances = $eventTarget.find('[data-toggle="offie"]')
  ;

  offieInstances.each(function(index, element) {
    var offie = $(element).data('offie');

    if (offie && offie.isOpened()) {
      offie.getTarget().css({'height': offie.getTarget()[0].scrollHeight});
    }
  })
});
