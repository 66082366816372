$('[data-content-selector=".offie-collapse"]').on('init.jq.offie', function(event) {
  var offie = event.offie,
      $target = offie.getTarget()
      scrollHeight = $target[0].scrollHeight
  ;

  if (offie.isOpened()) {
    $target.height(scrollHeight);
  }
});

$('[data-content-selector=".offie-collapse"]').on('showing.jq.offie', function(event) {
  var $target = event.offie.getTarget()
      scrollHeight = $target[0].scrollHeight;

  $target.height(scrollHeight);
});

$('[data-content-selector=".offie-collapse"]').on('shown.jq.offie', function(event) {
  var $target = event.offie.getTarget();

  $target.height('auto');
});

$('[data-content-selector=".offie-collapse"]').on('hiding.jq.offie', function(event) {
  var $target = event.offie.getTarget(),
      scrollHeight = $target[0].scrollHeight;

  $target.height(scrollHeight).height(0);
});
