// jQuery is not managed through webpack and is exported globally.
global.$ = global.jQuery = require('jquery');
// require('jquery-migrate');

const bootstrap = require('bootstrap-sass');

// Modernizr is crap, make it availble globally once and shut the fuck up.
global.Modernizr = require('modernizr');

require('bootstrap-datetime-picker');
require('bootstrap-datetime-picker/css/bootstrap-datetimepicker.min.css');
require('jquery.cookie');
require('cookieCuttr/jquery.cookiecuttr');
require('jquery-match-height');
// require('jquery-shorten');
require('imports-loader?define=>false,module=>false,exports=>false!jquery-validation');

require('redcurrents-bentokit/src/javascripts/compat.js');
require('redcurrents-bentokit/src/javascripts/collapse-extended.js');
require('redcurrents-bentokit/src/javascripts/collapse-scrollto.js');
require('redcurrents-bentokit/src/javascripts/front/_publication_collapses.js');
require('redcurrents-bentokit/src/javascripts/front/_publication_responsive.js');
// require('redcurrents-bentokit/src/javascripts/front/_event.js');
require('./javascripts/front/modules/offie-collapse.js');
require('imports-loader?_=lodash!redcurrents-bentokit/src/javascripts/offie.js');
require('imports-loader?_=lodash!redcurrents-bentokit/src/javascripts/buckle.js');
require('imports-loader?_=lodash!redcurrents-bentokit/src/javascripts/jquery.navbar-resizer.js');


require('./javascripts/front/matchheight-update.js');
require('./javascripts/front/svgsprite');
require('./javascripts/front/modules/gallery-deck.js');
require('./javascripts/front/main.js');

require('c3/c3.min.css');
require('./stylesheets/front/style.scss');

